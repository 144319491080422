<template>
    <div id="app">
        <div class="content pay-result">
            <van-image fit="contain" :src="state_successful" width="80"
                       class="pay-result-img"></van-image>
            <div class="pay-result-text">绑定成功</div>
            <div class="success-desc">已绑定收款码牌ID：{{code}}</div>


        </div>
    </div>
</template>

<script>
    import {Image} from "vant";

    export default {
        name: "bind_success",
        components:{
            [Image.name]: Image,
        },
        mounted() {
            const query = this.$route.query;
            if (query.code == undefined) {
                this.is_error = true
                return;
            } else {
                this.code = query.code
                console.log(query)
            }
        },
        data(){
            return{
                state_successful: require("../../assets/state_successful.png"),
                code:''
            }
        }
    }
</script>

<style scoped lang="less">
    #app {
        text-align: center;
        background: white;
        height: 100vh;
    }


    .pay-result {
        &-img {
            margin-top: 80px;
        }

        &-text {
            margin-top: 16px;
            font-weight: bold;
        }

        .fail-reason {
            margin-top: 10px;
        }

        .success-desc {
            margin-top: 10px;
            color: #888888;
        }

        .order-pay-platform {
            margin-top: 30px;
        }

        .money {
            margin-top: 10px;
            line-height: 40px;
            font-size: 30px;
        }
    }

</style>
